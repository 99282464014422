footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px 40px 100px;

    @media screen and (max-width: $desktop-l) {
        padding: 24px 16px 100px;
    }

    @media screen and (max-width: $desktop-m) {
        padding: 24px 16px 40px;
    }

    @media screen and (max-width: $desktop-xs) {
        gap: 20px;
    }

    @media screen and (max-width: $tablet-l) {
        padding: 24px 10px 85px;
    }

    @media screen and (max-width: $tablet-m) {
        gap: 12px;
    }

    .center__wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1920px;
        gap: 24px;
        margin: 0 auto;
        position: relative;

        @media screen and (max-width: $laptop-xl) {
            gap: 16px;
        }
    
        @media screen and (max-width: $tablet-m) {
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 0;
        }

        @media screen and (max-width: $tablet-l) {
            gap: 0;
        }
    
        @media screen and (orientation: landscape) {
            flex-direction: row;
        }

        .footer__nav {
            display: flex;
            flex-direction: row;
            flex-basis: 65%;
            gap: 24px;
            width: 100%;

            @media screen and (max-width: $desktop-xxl) {
                flex-basis: 100%;
                border-bottom: 1px solid $footer-border-color;
            }
        
            @media screen and (max-width: $laptop-xl) {
                flex-direction: column;
                flex-wrap: nowrap;
                text-align: center;
                flex-basis: 100%;
                gap: 16px;
                padding-bottom: 16px;
                margin-bottom: 16px;
            }
        
            @media screen and (max-width: $tablet-l) {
                flex-direction: column;
                gap: 0;
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }

            .footer__nav__item {
                display: flex;
                flex-direction: column;
                flex: 1 0 13%;

                @media screen and (max-width: $laptop-l) {
                    flex: 1 1 13%;
                }

                @media screen and (max-width: $tablet-l) {
                    margin-bottom: 12px;
                }

                &:nth-child(6) {
                    margin-right: 16px;

                    @media screen and (max-width: $desktop-xxl) {
                        margin-right: 0;
                    }

                    @media screen and (max-width: $desktop-s) {
                        margin-right: 16px;
                    }

                    @media screen and (max-width: $laptop-xl) {
                        margin-right: 0;
                    }
                }

                .footer__nav__title {
                    margin-bottom: 12px;
                    color: $text-light-color;
                    font-family: $primary-font-family;
                    font-size: 16px;
                    font-weight: $font-semibold;
                    line-height: 29px;
                    text-transform: capitalize;

                    @media screen and (max-width: $desktop-xs) {
                        margin-bottom: 21px;
                    }

                    @media screen and (max-width: $laptop-xl) {
                        margin: 14px 0;
                        font-size: 14px;
                        line-height: 1;
                    }
                }

                .footer__nav__list {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 21px;

                    @media screen and (max-width: $laptop-xl) {
                        padding-bottom: 0;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        column-gap: 12px;
                    }
                
                    @media screen and (max-width: $laptop-l) {
                        column-gap: 0;
                    }
                
                    @media screen and (max-width: $tablet-xl) {
                        width: calc(100% - 16px);
                        margin: 0 auto;
                    }
                
                    @media screen and (max-width: $tablet-m) {
                        padding: 0 16px;
                    }

                    @media screen and (max-width: $mobile-l) {
                        padding: 0 20px;
                    }

                    li {
                        display: flex;
                        align-items: center;
    
                        @media screen and (max-width: $desktop-xs) {
                            padding-bottom: 21px;
                        }
    
                        @media screen and (max-width: $laptop-xl) {
                            padding-bottom: 10px;
                        }
    
                        @media screen and (max-width: $laptop-l) {
                            padding: 0 8px 10px;
                        }
                        
                        a, span {
                            width: 100%;
                            color: $footer-link-color;
                            font-family: $primary-font-family;
                            font-weight: $font-regular;
                            font-size: 14px;
                            line-height: 36px;
                            text-decoration: underline;
                            text-decoration-color: transparent;
                            white-space: nowrap;
                            transition: text-decoration-color .3s ease-in-out;
                            cursor: pointer;
    
                            @media screen and (max-width: $desktop-xs) {
                                font-size: 12px;
                                line-height: 12px;
                            }
                        
                            @media screen and (max-width: $laptop-xl) {
                                justify-content: center;
                            }

                            @media screen and (max-width: $tablet-xl) {
                                width: max-content;
                            }
    
                            &:hover {
                                text-decoration-color: $footer-link-color;
                            }
                        }
                    }

                    .footer__nav__whatsapp a {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        text-decoration: none;
    
                        p {
                            text-decoration: underline;
                            text-decoration-color: transparent;
                            transition: text-decoration-color .3s ease-in-out;
                        }
    
                        &:hover p {
                            text-decoration-color: $footer-link-color;
                        }
    
                        span {
                            font-size: 9px;
                            font-weight: $font-light;
                        }
                    }
                }
            }
        }

        .footer__links {
            border-bottom: none;
            flex: 1 0 13%;
    
            @media screen and (max-width: $desktop-xxl) {
                flex: 1 1 34.3%;
            }

            @media screen and (max-width: $laptop-xl) {
                flex: 1 1 50%;
                flex-direction: row;
                gap: 24px;
                padding-bottom: 0;
                margin-bottom: 0;
            }

            @media screen and (max-width: $laptop-l) {
                flex-direction: column;
                flex: 0;
                gap: 16px;
                padding-right: 24px;
                border-right: 1px solid $footer-border-color;
                height: 270px;
            }

            @media screen and (max-width: $tablet-xl) {
                gap: 20px;
            }

            @media screen and (max-width: $tablet-l) {
                padding-right: 0;
                border-right: none;
                flex: 1;
                height: auto;
                gap: 0;
            }

            .footer__nav__item {    
                @media screen and (max-width: $tablet-l) {
                    border-top: 1px solid $footer-border-color;
                }

                &__social {
                    min-width: 205px;
                    flex: 1 0 18%;

                    @media screen and (max-width: $desktop-xxl) {
                        min-width: initial;
                        flex: 1 0 14%;
                    }
                }

                .footer__nav__title {
                    @media screen and (max-width: $desktop-xs) {
                        margin-bottom: 12px;
                    }

                    @media screen and (max-width: $laptop-xl) {
                        margin: 0 0 12px;
                        font-size: 14px;
                        line-height: 1;
                        text-align: left;
                    }

                    @media screen and (max-width: $tablet-l) {
                        padding-top: 12px;
                        text-align: center;
                    }
                }
            }
           

            .footer__logos {
                display: flex;
                align-items: center;

                @media screen and (max-width: $tablet-l) {
                    justify-content: center;
                }
            
                .footer__logos__regulator {
                    width: 98px;
                    height: 24px;
                    object-fit: cover;
                }

                .footer__logos__age {
                    display: flex;
                    min-width: 32px;
                    min-height: 32px;
                    justify-content: center;
                    align-items: center;
                    margin-left: 12px;
                    font-family: $primary-font-family;
                    font-weight: $font-semibold;
                    font-size: 12px;
                    line-height: 15px;
                    border: 1.5px solid $background-red-color;
                    border-radius: 50%;
                    text-transform: capitalize;
                    color: $text-light-color;
                    text-shadow: 0px 18.1818px 36.3636px rgba(0, 0, 0, 0.25);
                }
            }

            .footer__apps {
                display: flex;
                width: 152px;
                flex-direction: column;
                gap: 8px;
            
                @media screen and (max-width: $laptop-l) {
                    width: 220px;
                    flex-direction: row;
                    margin: 0 auto;
                }

                img {
                    display: inline-flex;
                    align-items: center;
                    height: 45px;
                    width: 100%;
                    cursor: pointer;

                    @media screen and (max-width: $laptop-l) {
                        height: 32px;
                    }
                }
            }

            .footer__socials {
                display: grid;
                grid-template-columns: repeat(3, 42px);
                gap: 8px;

                &__default {
                    margin-bottom: 20px;

                    @media screen and (max-width: $tablet-l) {
                        margin-bottom: 0;
                    }
                }
            
                @media screen and (max-width: $laptop-l) {
                    grid-template-columns: repeat(3, 26px);
                }
            
                @media screen and (max-width: $tablet-l) {
                    place-content: center;
                }                
                
                .footer__social {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 42px;
                    background: $footer-border-color;
                    border-radius: 5px;
                    cursor: pointer;
                
                    img {
                        width: 23px;
                        height: 23px;
                    }
                
                    @media screen and (max-width: $laptop-l) {
                        height: 26px;
                
                        img {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }
            }
        }

        .award__logo__wrapper {
            position: absolute;
            right: -24px;
            top: 44%;
        
            @media screen and (max-width: $desktop-xxl) {
                position: static;
                margin-top: 24px;
                padding-top: 24px;
                border-top: 1px solid $footer-border-color;
            }
        
            @media screen and (max-width: $laptop-xl) {
                justify-content: flex-end;
            }
        
            @media screen and (max-width: $laptop-l) {
                display: none;
            }
        }
        
        .award__logo__wrapper__tablet {
            display: none;
            border-top: none;
        
            @media screen and (max-width: $laptop-l) {
                display: flex;
                flex: 1 0 15%;
                justify-content: center;
                margin-top: 0;
                padding-top: 0;
            }
        
            @media screen and (max-width: $tablet-l) {
                display: none;
            }
        }
        
        .award__logo__wrapper__mobile.award__logo__wrapper__mobile {
            display: none;
        
            @media screen and (max-width: $tablet-l) {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 0;
                text-align: center;
                border-left: none;
                padding-top: 12px;
            }
        }
        
        .award__logo {
            width: 200px;
            height: 130px;
        
            @media screen and (max-width: $desktop-xxl) {
                width: 157px;
                height: 103px;
            }
        
            @media screen and (max-width: $laptop-m) {
                width: 145px;
                height: 93px;
            }
        
            @media screen and (max-width: $tablet-m) {
                width: 157px;
                height: 103px;
                margin: 0 auto;
            }
        }

        .footer__payments {
            flex-basis: 100%;

            @media screen and (max-width: $desktop-xxl) {
                flex: 1 0 0;
            }
        
            @media screen and (max-width: $laptop-l) {
                justify-content: center;
                flex-direction: column;
                flex: 0 0 33%;
                padding-right: 24px;
                padding-left: 24px;
                border-right: 1px solid $footer-border-color;
                height: 270px;
            }
        
            @media screen and (max-width: $tablet-l) {
                flex: 0 0 100%;
                margin-bottom: 0;
                padding-top: 12px;
                padding-right: 0;
                padding-left: 0;
                border-top: 1px solid $footer-border-color;
                border-right: none;
                height: auto;
            }

            @media screen and (max-width: $tablet-m) {
                flex: 0;
            }

            .footer__payments__title {
                font-size: 13px;
                font-weight: $font-semibold;
                margin-bottom: 12px;
                color: $text-light-color;
                font-family: $primary-font-family;
                line-height: 29px;
                text-transform: capitalize;

                @media screen and (max-width: $laptop-xl) {
                    font-size: 14px;
                    line-height: 1;
                }

                @media screen and (max-width: $tablet-l) {
                    text-align: center;
                }
            }

            .footer__payments__options {
                display: grid;
                grid-template-columns: repeat(auto-fill, 50px);
                gap: 8px;
                width: 100%;

                @media screen and (max-width: $laptop-xl) {
                    grid-template-columns: repeat(4, 47px);
                }

                @media screen and (max-width: $laptop-l) {
                    grid-template-columns: repeat(5, 47px);
                }

                @media screen and (max-width: $tablet-xl) {
                    grid-template-columns: repeat(4, 47px);
                }

                @media screen and (max-width: $tablet-l) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 0;
                }

                a {
                    height: 32px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 3px;
                    border-radius: 4px;
                    background-color: $background-light-color;

                    img {
                        max-width: 75%;
                        height: 25px;
                    }

                    @media screen and (max-width: $laptop-l) {
                        width: 100%;
                        max-width: 47px;
                    }

                    @media screen and (max-width: $tablet-l) {
                        max-width: 48px;
                        margin: 0 4px 8px;
                        border-radius: 2px;
                    }
                }
            }
        }
    }

    .footer__info {
        width: 100%;
        font-family: $primary-font-family;
        color: $text-light-color;
    
        @media screen and (max-width: $desktop-xxl) {
            padding-top: 16px;
            border-top: 1px solid $footer-border-color;
        }
    
        @media screen and (max-width: $tablet-l) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 12px;
        }

        .footer__info__title {
            font-size: 13px;
            line-height: 29px;
            font-weight: $font-semibold;
            text-transform: capitalize;
            margin-bottom: 12px;
            position: relative;

            @media screen and (max-width: $laptop-l) {
                text-align: left;
            }

            @media screen and (max-width: $tablet-l) {
                display: flex;
                align-items: center;
                text-align: center;
                gap: 8px;
            }
        }

        .footer__info__container {
            width: 100%;
            font-weight: $font-light;
            font-size: 11px;
            line-height: 18px;

            @media screen and (max-width: $tablet-m) {
                text-align: center;
            }

            p:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }

        &.accordion {
            @media screen and (max-width: $tablet-m) {
                .footer__info__title {
                    cursor: pointer;

                    &:after {
                        content: '';
                        width: 20px;
                        height: 10px;
                        transform: rotate(180deg);
                        transition: all 0.2s ease-out;
                        background: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.6667 6.21484L8.00008 10.8815L3.33342 6.21484' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
                    }
                }

                .footer__info__container {
                    max-height: 1000px;
                    overflow: hidden;
                    transition: all 0.2s ease-out;
                }
            }

            &.collapsed {
                @media screen and (max-width: $tablet-m) {
                    .footer__info__title::after {
                        transform: rotate(0deg);
                    }
    
                    .footer__info__container {
                        max-height: 0;
                    }
                }
            }
        }
    }
}
