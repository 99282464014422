[data-popup] {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    align-items: center;
    justify-content: center;
    padding: 30px;
    width: 100%;
    height: 100%;
    font-family: $primary-font-family;
    overflow: auto;
    z-index: -1;

    &.is__open {
        display: flex;
        background-color: $popup-overlay-color;
        scrollbar-width: none;
        overflow: auto;
        z-index: 1000;

        @media screen and (max-width: $mobile-xl) {
            padding: 0;
        }
    }

    .popup__container {
        margin: auto;
        display: flex;
        flex-direction: column;
        max-width: 414px;
        width: 100%;
        height: fit-content;
        min-height: auto;
        border-radius: 8px;
        background-color: $background-light-color;
        box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, .1);

        @media screen and (max-width: $mobile-xl) {
            min-height: 100%;
            max-width: 100%;
            border-radius: 0;
            box-shadow: none;
        }

        .popup__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 16px 16px 14px;
            width: 100%;
            height: 44px;

            &:has(> *:only-child) {
                justify-content: flex-end;
            }

            [alt='close'] {
                transition: all .3s ease-in-out;

                &:hover {
                    -webkit-transform: rotate(-90deg);
                    -ms-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                    stroke: $popup-close-icon-color;
                }
            }
        }

        .popup__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 0 16px 38px;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 7px;
                width: 100%;
                height: 40px;

                img {
                    width: auto;
                    height: 100%;
                }
            }
            
            form {
                .input__row {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 8px;
                    width: 100%;

                    @media screen and (max-width: $mobile-xl) {
                        margin-bottom: 10px;
                    }

                    label {
                        display: block;
                        margin-bottom: 5px;
                        width: 100%;
                        color: $text-dark-color;
                        font-size: 13px;
                        line-height: 15px;
                        font-weight: $font-medium;
                        text-align: left;

                        span {
                            font-size: 10px;
                            font-weight: $font-regular;
                        }
                    }

                    input {
                        display: block;
                        padding: 23px 8px;
                        max-width: 100%;
                        width: 100%;
                        max-height: 30px;
                        height: 100%;
                        border-radius: 4px;
                        border: 1px solid $input-border-color;
                        background: $input-background-color;
                        color: $text-dark-color;
                        font-size: 13px;
                        font-weight: $font-medium;
                        font-style: normal;
                        line-height: 125%;
                        outline: none;
                        -webkit-appearance: none;
                        appearance: none;
                        box-sizing: border-box;

                        &::placeholder {
                            font-size: 13px;
                            font-weight: $font-regular;
                            line-height: 1.25;
                            color: $text-gray-color;
                        }
                    
                        &:focus {
                            outline: none;
                        }
                    }

                    .hidden-input {
                        display: none;
                    }
                }

                .checkbox__row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 12px;
                    width: 100%;

                    @media screen and (max-width: $mobile-xl) {
                        margin-bottom: 16px;
                    }

                    .checkbox__wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 8px;

                        input[type="checkbox"] {
                            appearance: none; // Remove default checkbox
                            -webkit-appearance: none; // Remove default checkbox
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 20px;
                            height: 20px;
                            border: 1px solid $text-dark-color;
                            box-sizing: border-box;
                            border-radius: 2px;

                            &:before {
                                top: 0;
                                width: 18px;
                                height: 18px;
                                content: '';
                            }

                            &:checked {
                                &:before {
                                    background: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99804 0.488323C6.94953 0.439419 6.89183 0.400602 6.82824 0.374112C6.76466 0.347622 6.69646 0.333984 6.62758 0.333984C6.5587 0.333984 6.4905 0.347622 6.42692 0.374112C6.36334 0.400602 6.30563 0.439419 6.25713 0.488323L2.70283 4.0474L1.06968 2.40903C1.01932 2.36039 0.959869 2.32213 0.894724 2.29646C0.829578 2.27079 0.760013 2.2582 0.690001 2.25941C0.61999 2.26062 0.550902 2.27561 0.486684 2.30352C0.422465 2.33143 0.364373 2.37172 0.315723 2.42208C0.267074 2.47244 0.228821 2.53189 0.203148 2.59704C0.177475 2.66218 0.164884 2.73175 0.166095 2.80176C0.167307 2.87177 0.182296 2.94086 0.210207 3.00508C0.238119 3.0693 0.278406 3.12739 0.328768 3.17604L2.33237 5.17964C2.38087 5.22854 2.43858 5.26736 2.50217 5.29385C2.56575 5.32034 2.63395 5.33398 2.70283 5.33398C2.77171 5.33398 2.83991 5.32034 2.90349 5.29385C2.96707 5.26736 3.02478 5.22854 3.07328 5.17964L6.99804 1.25533C7.051 1.20647 7.09327 1.14717 7.12218 1.08116C7.15109 1.01516 7.16602 0.943883 7.16602 0.871825C7.16602 0.799768 7.15109 0.728491 7.12218 0.662487C7.09327 0.596483 7.051 0.537183 6.99804 0.488323Z' fill='%232D2A26'/%3E%3C/svg%3E%0A") no-repeat center center;
                                    background-size: 75% 75%;
                                }
                            }
                        }

                        label {
                            font-size: 11px;
                            font-weight: $font-regular;
                            line-height: 16px;
                        }
                    }

                    &.checkBoxWrapper__center {
                        justify-content: center;
                        margin: 3px 0 18px;
                    }
                }

                .error__message,
                .signup__birthdate__row .error-message,
                .signup-submit-error {
                    color: $input-error-color;
                    font-family: $primary-font-family;
                    font-weight: $font-regular;
                    font-size: 11px;
                    line-height: 13px;
                    margin-top: 4px;

                    &.signup-phone {
                        margin-left: 80px;
                    }

                    &#user__sms__code__error {
                        text-align: center;
                    }  
                }

                .divider {
                    position: relative;
                    width: 100%;
                    margin: 12px 0;
                    border-top: .5px solid $popup-divider-color;
                
                    @media screen and (max-width: $mobile-xl) {
                        margin: 10px 0 13px;
                    }
                }

                .agreement {
                    display: inline;
                    color: $text-black-color;
                    font-size: 10px;
                    font-weight: $font-regular;
                    line-height: 16px;
                    text-align: center;
                
                    @media screen and (max-width: $laptop-xl) {
                        font-size: 12px;
                    }
                
                    @media screen and (max-width: $mobile-xl) {
                        font-size: 9px;
                        line-height: 1.8;
                    }
        
                    a {
                        display: inline;
                        color: $text-black-color;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
