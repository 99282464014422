header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $background-dark-color;
    z-index: 100;

    .app__panel {
        display: none;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 0;
        padding: 8px 16px;
        background-color: #182133;

        &.open {
            display: flex;
            height: max-content;
        }

        &__title {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                display: flex;
                width: auto;
                height: 18px;
                margin-right: 8px;
            }

            span {
                display: flex;
                color: #ffffff;
                font-family: $primary-font-family;
                font-weight: $font-medium;
                font-size: 13px;
                line-height: 16px;
            }
        }

        &__link {
            display: flex;
            margin: 0 12px;
            padding: 8px;
            border: 1px solid #87F031;
            border-radius: 4px;
            color: #ffffff;
            font-family: $primary-font-family;
            font-weight: $font-semibold;
            font-size: 10px;
            line-height: 11px;
        }

        &__close {
            width: 16px;
            height: 16px;
        }
    }

    .center__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 11px 40px 11px 16px;
        width: 100%;
        max-width: 1920px;
        height: 100%;
        min-height: 100%;

        @media screen and (max-width: $desktop-l) {
            padding: 12px 16px;
        }

        @media screen and (max-width: $mobile-xl) {
            padding: 6px 16px 6px 10px;
        }

        .burger {
            display: flex;
            cursor: pointer;
            width: 48px;
            min-width: 48px;
            aspect-ratio: 1;
            margin: 0 22px 0 0;
            flex-direction: column;
            justify-content: center;
            background-color: $background-sidemenu-item-color;
            padding: 8px;
            border-radius: 4px;

            @media screen and (max-width: $tablet-m) {
                margin: 0 5px 0 0;
            }

            @media screen and (max-width: $mobile-xl) {
                margin: 0 0 0 0;
                background-color: transparent;
            }

            @media screen and (max-width: $mobile-s) {
                min-width: 42px;
                width: 42px;
                height: 42px;
            }

            .burger__line {
                width: 100%;
                height: 3px;
                border-radius: 5px;
                background: $background-light-color;
                transition: all .2s ease;

                @media screen and (max-width: $tablet-m) {
                    width: 32px;
                    height: 2px;
                }

                @media screen and (max-width: $mobile-s) {
                    width: 26px;
                }

                &:nth-child(2) {
                    margin: 7px 0;

                    @media screen and (max-width: $tablet-m) {
                        margin: 8px 0;
                    }

                    @media screen and (max-width: $mobile-s) {
                        margin: 6px 0;
                    }
                }
            }

            &.is__open {
                .burger__line {
                    margin: 0;
                    width: 35px;

                    @media screen and (max-width: $tablet-m) {
                        transition: all 0.3s ease;
                        width: 32px;
                        height: 2px;
                    }

                    @media screen and (max-width: $mobile-s) {
                        width: 26px;
                    }
    
                    &:nth-child(1) {
                        transform: rotate(-45deg) translate(-2px, 2px);

                        @media screen and (max-width: $mobile-s) {
                            transform: rotate(-45deg) translate(-2px, 3px);
                        }    
                    }

                    &:nth-child(2) {
                        width: 0;
                        transition: all 0s ease;
                        -webkit-transition: all 0s ease;
                        -moz-transition: all 0s ease;
                        -ms-transition: all 0s ease;
                        -o-transition: all 0s ease;
                    }

                    &:nth-child(3) {
                        transform: rotate(45deg) translate(-1px, -2px);

                        @media screen and (max-width: $tablet-m)  {
                            transform: rotate(45deg) translate(0, -1px);
                        }
                    }
                }
            }
        }

        .header__link__logo {
            position: relative;
            width: max-content;
            min-width: 105px;
            height: 40px;
            margin-right: 5px;

            @media screen and (max-width: $desktop-xs) {
                min-width: 86px;
                height: 36px;
            }

            @media screen and (max-width: $mobile-l) {
                min-width: 68px;
                height: 30px;
            }

            @media screen and (max-width: $mobile-s) {
                min-width: 60px;
                height: 36px;     
            }

            @media screen and (max-width: $mobile-xs) {
                min-width: 35px;
            }

            img {
                position: absolute;
                top: -5px;

                @media screen and (max-width: $desktop-xs) {
                    top: 0;
                }

                @media screen and (max-width: $mobile-s) {
                    top: 5px;
                }
            }
        }

        .header__nav {
            flex: 1;

            &.topmenu > ul {
                @media screen and (max-width: $desktop-m) {
                    display: none;
                }

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                li {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
                    padding: 0 12px;

                    @media screen and (max-width: $desktop-xxl) {
                        padding: 0;

                        &[data-type="gamification"] {
                            display: none;
                        }
                    }

                    a,
                    & > span {
                        padding: 11px;
                        border-radius: 8px;
                        color: $text-light-color;
                        font-family: $primary-font-family;
                        font-style: normal;
                        font-weight: $font-medium;
                        font-size: 16px;
                        line-height: 20px;
                        text-align: center;
                        text-transform: capitalize;
                        cursor: pointer;
                        transition: all .3s ease-in-out;

                        @media screen and (max-width: $desktop-xl) {
                            font-size: 14px;
                        }

                        &:hover {
                            background-color: $background-dark-hover-color;
                        }

                        img {
                            display: none;
                        }
                    }
                }
            }

            &.sidemenu .ul_wrapper {
                max-width: $side-menu-close-width;
                transition: all .3s;

                ul {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    scrollbar-width: none;
    
                    li {
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
    
                        .label {
                            transition: all .3s;
                            opacity: 0;
                            visibility: hidden;

                            &.small {
                                font-size: 7px;
                                line-height: 8.53px;
                            }
                        }
    
                        &.accordion .accordion__header .dropdown-caret {
                            transition: opacity .3s normal 0.15s;
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                }

                @media screen and (max-width: $laptop-xl) {
                    left: -100%;
                    visibility: hidden;
                    opacity: 0;
                }

                @media screen and (max-width: $laptop-xl) {
                    max-width: 100%;
                }
            }
        }

        .header__buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            .header__button__support {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border-radius: 4px;
                transition: background-color .3s ease-in-out;

                @media screen and (max-width: $laptop-xl) {
                    display: none;
                } 

                img {
                    width: 24px;
                    height: 24px;
                }

                &:hover {
                    background-color: $background-dark-hover-color;
                }
            }

            .header__button__signup,
            .header__button__login {
                width: 130px;
                height: 40px;
                border-radius: 4px;
                font-family: $primary-font-family;
                font-weight: $font-semibold;
                font-size: 16px;
                line-height: 19.5px;
                text-align: center;
                box-shadow: none;
                transition: background-color .3s ease-in-out;

                @media screen and (max-width: $mobile-xl) {
                    width: 80px;
                    height: 29px;
                    font-size: 10px;
                    line-height: 12.5px;
                }
            }

            .header__button_green {
                background-color: $button-green-primary-color;
                border: 1px solid $button-green-primary-color;
                color: $text-dark-color;

                &:hover {
                    background-color: $button-green-primary-hover-color;
                }
            }

            .header__button_green-outlined {
                background-color: transparent;
                border: 1px solid $button-green-primary-color;
                color: $text-light-color;

                &:hover {
                    background-color: $button-green-secondary-hover-color;
                }
            }
        }
    }
}
